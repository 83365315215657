import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BACKEND_BASE_URL } from '../helpers/environment';
import { CreatePost, Post } from '../types/post';

export const postApi = createApi({
    reducerPath: "postApi",
    baseQuery: fetchBaseQuery({ baseUrl: `${BACKEND_BASE_URL}/posts` }),
    endpoints: (builder) => ({
        postPostForMainRoom: builder.mutation<Post, CreatePost>({
            query: (post) => ({
                url: "",
                method: "POST",
                body: post,
            }),
        }),
    }),
})

export const { usePostPostForMainRoomMutation } = postApi;