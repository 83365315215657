import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import RoomPage from "./pages/roomPage";
import LandingPage from "./pages/landingPage";
import { AddPostPage } from "./pages/addPostPage";
import { Footer } from "./components/footer";

export default function App() {
    const theme = createTheme({});

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/room/:roomUuid" element={<RoomPage />} />
                    <Route path="/room/:roomUuid/createPost" element={<AddPostPage />} />
                </Routes>
                <Footer />
            </BrowserRouter>
        </ThemeProvider>
    )
}