import { configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"

import { roomApi } from "../services/room"
import createPostSlice from "./slices/createPostSlice";

export const store = configureStore({
    reducer: {
        [roomApi.reducerPath]: roomApi.reducer,
        createPost: createPostSlice,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(roomApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>

setupListeners(store.dispatch);
