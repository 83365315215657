import { Rating, Typography } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectCreatePostQuestionById, updateAnswer } from "../../store/slices/createPostSlice";

interface RatingAnswerProps {
    questionId: number;
}

export default function RatingAnswerAnswer(props: RatingAnswerProps) {
    const dispatch = useDispatch();
    const [value, setValue] = useState<number | null>(null);

    const postQuestion = useSelector(selectCreatePostQuestionById(props.questionId))

    return (
        <>
            <Typography sx={{marginTop: 3}} component="legend">{postQuestion?.description}</Typography>
             <Rating
                sx={{m: 1}}
                value={value}
                onChange={(_, newValue) => {
                    setValue(newValue);
                    dispatch(updateAnswer({ questionId: props.questionId, data: {rating: newValue} }));
                }}
            />
        </>
    );
}