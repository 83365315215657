import { Typography } from "@mui/material";

import { PostQuestionAnswer } from "../../types/postQuestionAnswer";

interface TextAnswerProps {
    answer: PostQuestionAnswer
}

export function TextAnswerVisualizer(props: TextAnswerProps) {
    const answerText = ((props.answer.data) as any).text as string;

    return (
        <>
            <Typography sx={{}} variant="body2" color="text.secondary">
                - {props.answer.postQuestion.description}
            </Typography>

            <Typography sx={{marginBottom: 2, fontStyle: "italic"}} variant="body2" color="text.secondary">
                "{answerText}"
            </Typography>
        </>
    )
}
