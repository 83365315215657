import { Send } from "@mui/icons-material";
import { Container, Box, TextField, Typography, Button, Stack, Paper, Divider } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import RatingAnswerAnswer from "../components/answerTypes/ratingAnswer";
import TextAnswer from "../components/answerTypes/textAnswer";

import { usePostPostForMainRoomMutation } from "../services/post";
import { useGetRoomPostQuestionsByUuidQuery } from "../services/room";
import { addQuestion, selectCreatePost, setRoomId } from "../store/slices/createPostSlice";

export function AddPostPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [name, setName] = useState<string>("");

    const { data, error, isLoading } = useGetRoomPostQuestionsByUuidQuery("1");
    const createPostState = useSelector(selectCreatePost);

    const [postPost, result] =  usePostPostForMainRoomMutation();

    if (data && !createPostState.roomId) {
        dispatch(setRoomId(1));
        data?.forEach((question) => dispatch(addQuestion(question)));
    }

    if (isLoading) {
        return <h1>Einen Moment</h1>
    }

    return (
        <Container>
            <Box style={{display: "flex", flexDirection: "column", alignItems: "center", marginTop: 8, textAlign:"center"}}>
                <Typography sx={{m: 2}} variant="h5">Post hinzufügen</Typography>

                <TextField
                    sx={{m: 2}}
                    label="Dein Name"
                    variant="outlined"
                    onChange={(event) => setName(event.target.value)}
                />

                {data?.map((question) => {
                    if (question.questionType === "TEXT") return <TextAnswer questionId={question.id} />
                    if (question.questionType === "RATING") return <RatingAnswerAnswer questionId={question.id} />
                })}

                <div>
                    <Button
                        sx={{m:2}}
                        variant="outlined"
                        onClick={() => {
                            navigate("/room/1")
                        }}
                    >
                        Zurück
                    </Button>
                    <Button
                        sx={{m:2}}
                        variant="contained"
                        endIcon={<Send />}
                        onClick={() => {
                            postPost({
                                creatorName: name,
                                questionAnswers: createPostState.answers,
                            }).finally(() => navigate("/room/1"));
                        }}
                    >
                        Absenden
                    </Button>
                </div>
                
            </Box>
        </Container>
    );
}
