import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { BACKEND_BASE_URL } from '../helpers/environment';
import { PostQuestion } from '../types/postQuestion';
import { Room } from '../types/room'

export const roomApi = createApi({
    reducerPath: "roomApi",
    baseQuery: fetchBaseQuery({ baseUrl: `${BACKEND_BASE_URL}/rooms` }),
    endpoints: (builder) => ({
        getRoomByUuid: builder.query<Room, string>({
            query: (uuid) => uuid,
            transformResponse: (response: Room, meta, arg) => {
                return {
                    id: response.id,
                    name: response.name,
                    shortDescription: response.shortDescription,
                    posts: response.posts.map((rawPost) => ({
                        id: rawPost.id,
                        creatorName: rawPost.creatorName,
                        createdAt: new Date(rawPost.createdAt),
                        answers: rawPost.answers,
                    }))
                }
            },
        }),
        getRoomPostQuestionsByUuid: builder.query<PostQuestion[], string>({
            query: (uuid) => uuid,
            transformResponse: (response: any, meta, arg) => response.postQuestions,
        }),
    }),
})

export const { useGetRoomByUuidQuery, useGetRoomPostQuestionsByUuidQuery } = roomApi;