import { TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { selectCreatePostQuestionById, updateAnswer } from "../../store/slices/createPostSlice";

interface TextAnswerProps {
    questionId: number;
}

export default function TextAnswer(props: TextAnswerProps) {
    const dispatch = useDispatch();

    const postQuestion = useSelector(selectCreatePostQuestionById(props.questionId))


    return (
        <>
            <Typography sx={{marginTop: 3}} component="legend">{postQuestion?.description}</Typography>
            <TextField
                sx={{m: 1}}
                variant="outlined"
                onChange={(event) => dispatch(updateAnswer({ questionId: props.questionId, data: {text: event.target.value} }))}
            />
        </>
    )
}