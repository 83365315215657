const RELATIVE_TIME_FORMAT = new Intl.RelativeTimeFormat()

export function dateToRelativeDateFormat(date: Date) {
    const now = Date.now();

    console.log(now - date.getTime())

    if (now - date.getTime() < 1000 * 60 * 60) {
        return RELATIVE_TIME_FORMAT.format(Math.round((date.getTime() - now) / 1000 / 60), "minutes");
    }

    if (now - date.getTime() < 1000 * 60 * 60 * 12) {
        return RELATIVE_TIME_FORMAT.format(Math.round((date.getTime() - now) / 1000 / 60 / 60), "hour");
    }

    return date.toLocaleDateString();
}