export function Footer() {
    return (
        <div style={{
            marginTop: "1rem",
            padding: "1rem",
            position:"fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            backgroundColor: "white",
            textAlign: "center"
        }}>
            <p style={{fontSize: 8}}>Diese Webseite ist kein offizielles Angebot der Feuerwehr Woltringhausen</p>
        </div>
    )
}