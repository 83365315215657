import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { PostQuestion } from "../../types/postQuestion";
import { RootState } from "../store";

interface Answer {
    questionId: number;
    data: object;
}

interface CreatePostState {
    roomId?: number;
    questions: PostQuestion[];
    answers: Answer[];
}

const initialState: CreatePostState = {
    roomId: undefined,
    questions: [],
    answers: [],
}

export const createPostSlice = createSlice({
    name: "createPost",
    initialState,
    reducers: {
        setRoomId: (state, action: PayloadAction<number>) => ({
            ...state,
            roomId: action.payload,
        }),
        addQuestion: (state, action: PayloadAction<PostQuestion>) => ({
            ...state,
            questions: [...state.questions, action.payload],
        }),
        updateAnswer: (state, action: PayloadAction<Answer>) => ({
            ...state,
            answers: [
                ...state.answers.filter((answer) => answer.questionId !== action.payload.questionId),
                action.payload
            ],
        }),
        reset: (state) => initialState,
    },
});

export const { setRoomId, addQuestion, updateAnswer, reset } = createPostSlice.actions;

export const selectCreatePost = (state: RootState) => state.createPost;
export const selectCreatePostQuestionById =
    (id: number) => (state: RootState) => state.createPost.questions.find((question) => question.id === id);

export default createPostSlice.reducer;