import { Rating, Typography } from "@mui/material";

import { PostQuestionAnswer } from "../../types/postQuestionAnswer";

interface RatingAnswerProps {
    answer: PostQuestionAnswer
}

export function RatingAnswerVisualizer(props: RatingAnswerProps) {
    const rating = ((props.answer.data) as any).rating as number;

    return (
        <>
            <Typography sx={{}} variant="body2" color="text.secondary">
                - {props.answer.postQuestion.description}
            </Typography>

            <Rating readOnly defaultValue={rating} />
        </>
    )
}
