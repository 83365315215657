import { Edit } from "@mui/icons-material";
import { Avatar, Box, Container, Divider, Fab, List, Skeleton, Typography } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";

import PostListItem from "../components/postListItem";
import { useGetRoomByUuidQuery } from "../services/room";

export default function RoomPage() {
    const [errorCount, setErrorCount] = useState(0);


    const { data, error, isLoading } = useGetRoomByUuidQuery("1", { pollingInterval: 5000, refetchOnMountOrArgChange: true });


    if (error) {
        setErrorCount(errorCount +1)
    } else if (errorCount) {
        setErrorCount(0);
    }
     

    if (errorCount > 3) {
        return <h1>We fucked up</h1>
    }

    return (
        <Container>
            <Box style={{display: "flex", flexDirection: "column", alignItems: "center", marginTop: 8, textAlign:"center"}}>
                <Avatar>
                    <img style={{height: "100%"}} src={require("../fire.gif")} />
                </Avatar>

                <Typography sx={{m: 2}} variant="h5">{isLoading ? <Skeleton /> : data?.name}</Typography>

                {isLoading ? <Skeleton variant="rectangular" /> : <img src={require("../fire-truck.png")} />}

                <Typography sx={{m: 3, width: "75%"}} variant="body1">
                    <i>
                        {isLoading ? <Skeleton /> : data?.shortDescription}
                    </i>
                </Typography>

                <Fab sx={{marginBottom: 2, marginTop: 2}} variant="extended" component={Link} to="createPost">
                    <Edit />
                    Bericht hinterlassen
                </Fab>

                <List>
                    {data?.posts.map(post => {
                        return (
                            <>
                                <PostListItem
                                    creatorName={post.creatorName}
                                    creationDate={post.createdAt}
                                    answers={post.answers}
                                />
                                <Divider variant="inset" component="li" />
                            </>
                        );
                    })}
                </List>
            </Box>
        </Container>
    )
}
